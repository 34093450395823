<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <g>
      <g transform="translate(0 4.357)">
        <circle cx="2.151" cy="2.151" r="2.151" fill="currentColor" />
      </g>
      <path
        fill="currentColor"
        d="M12.924 44.535a41.652 41.652 0 0 1-5.542-18.82 15.987 15.987 0 0 1 1-7.149 8.312 8.312 0 0 1 6.545-5.169 10.432 10.432 0 0 1 6 1.562c.2.105.391.212.587.319a10.328 10.328 0 0 0 3.071 1.306 1.723 1.723 0 0 0 1.6-.425 4.391 4.391 0 0 0-.075-1.874 8.835 8.835 0 0 1 .059-4.056c.891-2.9 3.838-4.36 6.364-4.527a16.638 16.638 0 0 1 5.008.642c.322.076.644.153.966.224a6.472 6.472 0 0 0 3.751.024c.624-.281.938-.982.86-1.159l4.457-1.97c1.34 3.033-.633 6.364-3.316 7.572a10.757 10.757 0 0 1-6.8.292c-.348-.076-.7-.16-1.044-.242a12.561 12.561 0 0 0-3.559-.519 2.332 2.332 0 0 0-2.026 1.1 5.31 5.31 0 0 0 .1 1.883 7.653 7.653 0 0 1-.361 4.749 6.306 6.306 0 0 1-6.731 3.106 14.255 14.255 0 0 1-4.658-1.846l-.552-.3a6.235 6.235 0 0 0-3.187-1.007 3.574 3.574 0 0 0-2.582 2.264 11.579 11.579 0 0 0-.6 4.974 36.767 36.767 0 0 0 4.891 16.609Z"
      />
    </g>
  </svg>
</template>
